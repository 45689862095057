<template>
  <div class="history_post_warp">
    <PullRefresh
      :loading="loading"
      :refreshing="refreshing"
      :finished="finished"
      @onLoad="onLoad"
      :is-higeht-max="true"
      :disabled="true"
      :isNoData="isNoData"
    >
      <div class="postBox" v-for="item in list" :key="item.id">
        <div class="selectBox" @click.stop="selectItem(item)" :class="{ activeSelectBox: isEdit }">
          <svg-icon class="select" iconClass="batchSelected" v-if="item.select" />
          <svg-icon class="select" iconClass="batchUnSelect" v-else />
        </div>
        <div class="main" :class="{ activeMain: isEdit }">
          <Post :postInfo="item" class="postItem" />
        </div>
      </div>
    </PullRefresh>
  </div>
</template>
<script>
import Post from '@/components/Post';
import { queryHistoryVideo } from '@/utils';
import PullRefresh from '@/components/PullRefresh';
import { deleteDB, openDB } from '@/utils/indexedDB';
import { Toast } from 'vant';

export default {
  props: ['isEdit'],
  name: 'HistoryPost',
  components: {
    Post,
    PullRefresh,
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 20,
      list: [],
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      error: false,
    };
  },
  async created() {
    this.getList();
  },
  methods: {
    //获取列表
    async getList(type) {
      let list = (await queryHistoryVideo(this.pageNumber, this.pageSize, 'COVER')) || [];
      if (type === 'refresh') {
        this.list = list;
      } else {
        this.list = this.list.concat(list);
      }
      this.list = this.uniqueFun(this.list, 'id');
      if (this.pageNumber == 1 && this.list.length == 0) {
        this.isNoData = true;
      }
      if (list.length < this.pageSize) {
        this.finished = true;
        return;
      }
      this.loading = false;
    },
    // 选择批量删除的数据
    selectItem(work) {
      this.list.map((item) => (item.id == work.id ? this.$set(item, 'select', !item.select) : ''));
    },
    // 选择全部
    selectAll(isSelectAll) {
      this.list.map((item) => this.$set(item, 'select', isSelectAll));
    },
    // 批量删除
    batchDel() {
      const delArr = this.list.map((item) => (item.select ? item.id : undefined)).filter((item) => item);
      if (delArr.length == 0) return Toast('至少选择一项进行删除');
      this.delItem(delArr);
    },
    uniqueFun(arr, type) {
      const res = new Map();
      return arr.filter((a) => !res.has(a[type]) && res.set(a[type], 1));
    },
    async delItem(delArr) {
      let db = await openDB('rms_db');
      this.list = this.list.filter((item) => {
        let flag = delArr.find((vId) => item.id == vId);
        if (flag) {
          deleteDB(db, 'mediaInfos', flag);
        } else {
          return item;
        }
      });
      if (this.list.length == 0) return this.$emit('close');
    },
    onLoad() {
      this.loading = true;
      this.pageNumber++;
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.history_post_warp {
  height: 100%;
  .postBox {
    margin-bottom: 10px;
    display: flex;
    width: 100%;
    .selectBox {
      padding: 0 11px;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translateX(-44px);
      transition: transform 0.3s;
      .select {
        height: 22px;
        width: 22px;
        border-radius: 50%;
      }
    }
    .main {
      width: 100%;
      background-color: #fff;
      padding: 16px 0;
      transform: translateX(-44px);
      transition: transform 0.3s;
      .postItem {
        width: 350px;
        margin: 0 auto;
        padding: 0px 16px;
      }
    }
    .activeSelectBox {
      transform: translateX(0px);
      transition: transform 0.3s;
    }
    .activeMain {
      transform: translateX(0px);
      transition: transform 0.3s;
    }
  }
}
</style>
